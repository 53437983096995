import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

export const FyChip = styled(Chip)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'uppercase',
    fontSize: 14,
    padding: '2px 0px',
    height: 'auto',
    border: 0,
    borderRadius: '4px',
    backgroundColor: ctx.theme.palette.grey[200],
    color: '#000',
    '&:disabled': {
        color: '#ccc',
        boxShadow: 'none',
    },
    '&:hover': {
        border: 0,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ...ctx.style,
}))
